import { Interface } from '@ethersproject/abi';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { BigNumber } from '@ethersproject/bignumber';
import { useMutation, UseMutationOptions } from '@tanstack/vue-query';
import { ref, unref } from 'vue';
import { erc721Abi, erc1155Abi } from '@/abis';
import apiHelpers from '@/api/apiHelpers';
import {
  sleep
} from '@/lib/claimUtil';
import { useClaimStore } from '@/store/claimStore';

type UseMintOptions = UseMutationOptions<
{ tx: TransactionResponse; tokenIds: BigNumber[] | undefined },
unknown,
{ amount: number },
unknown
>

export function useMint (options?: UseMintOptions) {
  const store = useClaimStore();

  const tx = ref<TransactionResponse>();
  const txState = ref<'idle' | 'confirming' | 'success' | 'error'>('idle');
  const tokenIds = ref<BigNumber[]>();

  const { mutate: mint, mutateAsync: mintAsync, ...rest } = useMutation(
    async ({ amount }: { amount: number }) => {
      txState.value = 'idle';

      if (!store.isConnected || !store.mintForWallet || !store.walletAddress) {
        throw new Error('Connect your wallet to continue');
      }

      let _tx: TransactionResponse;
      if (amount === 1) {
        _tx = await store.contract.mint(
          store.claimableMintIndices[0] || 0,
          store.claimableMerkleProofs[0] || null,
          // If erc20, then the "cost" is 0 here... (it will add the fee if applicable)
          store.erc20Address ? undefined : store.cost,
          store.walletAddress,
          store.mintForWallet
        );
      } else {
        _tx = await store.contract.mintBatch(
          amount,
          store.claimableMintIndices.slice(0, amount),
          store.claimableMerkleProofs.slice(0, amount),
          // If erc20, then the "cost" is 0 here... (it will add the fee if applicable)
          store.erc20Address ? undefined : store.cost.mul(amount),
          store.walletAddress,
          store.mintForWallet
        );
      }

      tx.value = _tx;
      txState.value = 'confirming';

      apiHelpers.post('/public/instance/tx', {
        query: {
          appId: store.appId,
          instanceId: store.id,
          hash: _tx.hash
        }
      }).catch(() => {
        console.error('Failed to post tx hash to public instance api');
      });

      if (!store.isProviderAvailable) {
      // HACK: since claims supports minting without a provider
      // (e.g. walletconnect) we don't have the ability
      // to wait until the transaction has been mined.
      // Pausing here allows us to have a good chance
      // that the block will be mined before a ui
      // refresh happens which avoids a number of
      // confusing ui edge cases.
        await sleep(60_000);
      } else {
        const receipt = await _tx.wait(1);

        try {
          if (receipt && receipt.logs) {
            const ids = [];
            for (const log of receipt.logs) {
              try {
                if (store.is721) {
                  const iface = new Interface(erc721Abi);
                  const parsed = iface.parseLog(log);
                  ids.push(parsed.args.tokenId);
                } else {
                  const iface = new Interface(erc1155Abi);
                  const parsed = iface.parseLog(log);
                  ids.push(parsed.args.id);
                }
              } catch (e) {
                // Do nothing, log will failed parse for events specific to extension contract
              }
            }

            tokenIds.value = ids;
          }
        } catch (e) {
          // tx is already complete; no need to throw
          console.error(e);
        }
      }

      store.refreshWeb3State();
      txState.value = 'success';

      return {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        tx: unref(tx)!,
        tokenIds: unref(tokenIds)
      };
    },
    {
      ...options,
      onError: (err, variables, context) => {
        txState.value = 'error';
        options?.onError?.(err, variables, context);
      }
    });

  return {
    ...rest,
    mint,
    mintAsync,
    tx,
    txState,
    tokenIds
  };
}
