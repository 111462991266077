/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BigNumber } from '@ethersproject/bignumber';
import { formatBytes32String } from '@ethersproject/strings';
import { formatEther, formatUnits } from '@ethersproject/units';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { setProviderEventsHandler } from '@manifoldxyz/manifold-dropsitetools-lib';
import { MediaBackgroundConfig } from '@manifoldxyz/vue-component-library/dist/types/mediaBackground';
import { getERC20ToUSDRate, getEthToUsdRate } from '@/api/coinbase';
import collectors, { IMerkleInfo } from '@/api/collectors';
import { CrossmintVerificationStatus, getCrossmintVerificationStatus } from '@/api/crossmint';
import ClaimExtensionContract, { StorageProtocol } from '@/classes/claimExtensionContract';
import ERC20Contract from '@/classes/erc20Contract';
import {
  DELEGATION_REGISTRY_ADDRESS,
  EXTENSION_TRAITS,
  FEE_PER_MERKLE_MINT,
  FEE_PER_MINT,
  NETWORK_ID,
  NULL_ADDRESS
} from '@/common/constants';
import { useDelegates } from '@/composables/delegate';
import { resolveAddress } from '@/lib/address';

export enum ClaimType {
  ERC721 = 'erc721',
  ERC1155 = 'erc1155',
}

export type Creator = {
  id: number;
  address: string;
  name: string;
  image: string | null;
  twitterUrl?: string;
};

export type MintLimitType = 'whitelist' | 'limited' | 'open';

export type WalletRestrictionType = 'allowlist-limit' | 'wallet-limit' | 'no-limit'

/** visual customisations for the collector side */
export interface ClientTheme {
  /** background display for the claim app. can be an image URL, video URL, or a background color */
  appBackground?: {
    /** url for the image */
    image?: string;
    /** url for the src property of a video element */
    video?: string;
    /** css-readable color string */
    backgroundColor: string;
  };
  mediaBackground?: MediaBackgroundConfig;
  /** theme colors used in the collector app */
  colors?: {
    /** css-readable color string for primary color accents. defaults to 'white' if unset. */
    themePrimary: string;
    /** css-readable color string for secondary color accents (e.g.: provenance headers). defaults to white @ 50% opacity if unset. */
    themeSecondary: string;
  };
  /** emojis to display on checkout. */
  checkoutEmojis?: string[];
  /** custom animation to move around the page */
  animation?: {
    /** url for image source */
    source: string;
    /** width of animation in px */
    width: number;
    /** height of animation in px */
    height: number;
    /** whether animations should be played on mobile. defaults to false. */
    playOnMobile?: boolean;
  };
}

export type InstanceResponse = {
  id: number;
  appId: number;
  slug: string;
  creator: Creator;
  publicData: {
    name: string;
    image: string;
    description: string;
    audienceId: number | null;
    merkleTreeId: number | undefined;
    extensionAddress: string;
    creatorContractAddress: string;
    claimIndex: number;
    claimType: string;
    /** tokenUrl is legacy, keeping it here for backward compatibility */
    tokenUrl?: string;
    /** storing animation moving forward, but legacy instances will not have this */
    animation?: string;
    networkId?: number;
    canPayWithCard?: boolean;
    clientTheme?: ClientTheme;
    crossmintClientId?: string;
    erc20?: string;
  }
}

export const useClaimStore = defineStore('claim', () => {
  /**
   * STATE
   */

  /**
   * Note: Setting a default state is required. Many
   * values are overwritten at time of initialization but the
   * typings they establish are important.
   *
   * If after initialization a value is guaranteed to
   * not be `undefined`, set a default value here.
   */

  // instance data
  const id = ref(0);
  const appId = ref(0);
  const name = ref('');
  const image = ref('');
  const description = ref('');
  const slug = ref('');
  const creator = ref<Creator>({} as Creator);
  const audienceId = ref<number | null>(null);
  const merkleTreeId = ref<number | undefined>(undefined);
  const extensionAddress = ref('');
  const creatorContractAddress = ref('');
  const claimIndex = ref(0);
  const claimType = ref(ClaimType.ERC721);
  const tokenUrl = ref<string | undefined>();
  const animation = ref<string | undefined>();
  const fallbackProvider = ref<string>();
  const networkId = ref(1);
  const canPayWithCard = ref(true);
  const crossmintClientId = ref<string>('');
  const _clientTheme = ref<ClientTheme>({} as ClientTheme);

  // on-chain claim data
  const total = ref(0);
  const totalMax = ref<number | null>(null);
  const walletMax = ref<number | null>(null);
  const merkleInfo = ref<IMerkleInfo[]>([]);
  const startDate = ref<Date | null>(null);
  const endDate = ref<Date | null>(null);
  const storageProtocol = ref(StorageProtocol.ARWEAVE);
  const merkleRoot = ref(formatBytes32String(''));
  const location = ref('');
  const tokenId = ref<BigNumber | null>(null);
  const cost = ref(BigNumber.from(0));
  const erc20Address = ref<string>('');

  // store data
  const initialized = ref(false);
  const contract = ref({} as ClaimExtensionContract);
  const erc20Contract = ref({} as ERC20Contract);
  const erc20Symbol = ref<string>('ETH');
  const erc20Decimals = ref<number>(18);
  const erc20ApprovedSpend = ref<BigNumber>(BigNumber.from(0));
  const isLoadingWeb3State = ref(false);

  const tokensToMint = ref(1);
  const ethToUsdRate = ref(1);
  const erc20ToUsdRate = ref(1);
  /**
   * The number of tokens the current wallet has minted.
   * - `null` if the claim has no wallet max or allow list (data is not tracked)
   * - otherwise the number of tokens minted by the wallet
   */
  const tokensMintedByWallet = ref<number | null>(null);
  const activeNetwork = ref<number>();
  const walletAddress = ref<string>();
  const ensOrFormattedWalletAddress = ref<string>();
  const creatorEnsOrFormattedWalletAddress = ref<string>();
  const balance = ref<BigNumber>();
  const isProviderAvailable = ref(false);
  const isCrossmintVerified = ref(false);

  /**
   * The number of allowlist mints available
   * for the current wallet
   */
  const claimableMintIndices = ref<number[]>([]);
  const claimableMerkleProofs = ref<string[][]>([]);

  /**
   * Wallet that user has selected to mint for on behalf of their logged in wallet (Delegation Registry). To learn more: https://delegate.cash/
   * This value is defaulted to the logged in wallet address.
   */
  const mintForWallet = ref<string>();

  /**
   * Composable to compute eligible vault wallets from delegation registry
   */
  const { isLoading: isDelegationLoading, eligibleVaultWallets, error: delegationError } = useDelegates(walletAddress, DELEGATION_REGISTRY_ADDRESS, { checkWalletsEligibility, checkAll: true });

  /**
   * ACTIONS
   */
  async function initialize (instance: InstanceResponse, fallbackProvider?: string) {
    _initializeInstanceData(instance);
    await _initializeProvider(fallbackProvider);
    setInterval(_setPriceRates, 30000);

    contract.value = new ClaimExtensionContract(
      extensionAddress.value,
      creatorContractAddress.value,
      claimIndex.value
    );

    if (instance.publicData.erc20 && instance.publicData.erc20 !== NULL_ADDRESS) {
      erc20Contract.value = new ERC20Contract(
        instance.publicData.erc20
      );
      erc20Symbol.value = await erc20Contract.value.getERC20Symbol();
      erc20Decimals.value = await erc20Contract.value.getERC20Decimals();
      erc20Address.value = instance.publicData.erc20;
    }

    await _setPriceRates();
    await refreshOnChainClaim();
    await _initializeCrossmint();

    initialized.value = true;
  }

  async function _initializeCrossmint () {
    if (crossmintClientId.value) {
      const verifyResponse = await getCrossmintVerificationStatus(crossmintClientId.value);
      const sellerStatus = verifyResponse?.verificationStatus?.seller?.status;
      const collectionStatus = verifyResponse?.verificationStatus?.collection?.status;
      isCrossmintVerified.value = sellerStatus === CrossmintVerificationStatus.VERIFIED && collectionStatus === CrossmintVerificationStatus.VERIFIED;
    }
  }

  async function _setPriceRates () {
    ethToUsdRate.value = (await getEthToUsdRate()) ?? 0;
    if (erc20Address.value !== NULL_ADDRESS) {
      erc20ToUsdRate.value = (await getERC20ToUSDRate(erc20Symbol.value)) ?? 0;
    }
  }

  async function _initializeProvider (fallbackProvider?: string) {
    await window.ManifoldEthereumProvider.initialize(networkId.value, fallbackProvider);
    const provider = window.ManifoldEthereumProvider.provider();
    const signingProvider = window.ManifoldEthereumProvider.provider(true);

    if (signingProvider && provider !== signingProvider) {
      isProviderAvailable.value = false;
    } else {
      isProviderAvailable.value = !!provider;
    }

    setProviderEventsHandler(async () => {
      const eth = window.ManifoldEthereumProvider;
      const provider = eth.provider();
      const signingProvider = eth.provider(true);
      const address = eth.selectedAddress();
      const chainId = eth.chainId();

      walletAddress.value = address;
      ensOrFormattedWalletAddress.value = await resolveAddress(address || '');
      creatorEnsOrFormattedWalletAddress.value = await resolveAddress(creator.value.address || '');
      if (erc20Address.value) {
        erc20ApprovedSpend.value = await erc20Contract.value.getAllowance(extensionAddress.value, walletAddress.value!);
      }
      try {
        balance.value = await eth.provider()?.getBalance(address || '');
      } catch {
        balance.value = undefined;
      }

      // Default mintForWallet to walletAddress, this value can change if user selects a different wallet to mint for on UI
      mintForWallet.value = walletAddress.value;

      /**
       * NOTE: If a browser provider like MetaMask is available
       * chainId will be present, however with WalletConnect
       * there is often no provider available and therefore no chainId
       * either. We can only rely on a hardcoded value `NETWORK_ID`.
       */
      activeNetwork.value = chainId || NETWORK_ID;

      if (signingProvider && provider !== signingProvider) {
        isProviderAvailable.value = false;
      } else {
        isProviderAvailable.value = !!provider;
      }
    });
  }

  function _initializeInstanceData (instance: InstanceResponse) {
    // TODO: use zod, this is all a lie
    id.value = instance.id;
    appId.value = instance.appId;
    slug.value = instance.slug;
    creator.value = instance.creator;

    const publicData = instance.publicData;
    name.value = publicData.name;
    image.value = publicData.image;
    description.value = publicData.description;
    audienceId.value = publicData.audienceId;
    merkleTreeId.value = publicData.merkleTreeId;
    extensionAddress.value = publicData.extensionAddress;
    creatorContractAddress.value = publicData.creatorContractAddress;
    claimIndex.value = publicData.claimIndex;
    claimType.value = publicData.claimType.toLowerCase() as ClaimType;
    tokenUrl.value = publicData.tokenUrl;
    animation.value = publicData.animation;
    networkId.value = publicData.networkId || NETWORK_ID;
    canPayWithCard.value = publicData.canPayWithCard || false;
    crossmintClientId.value = publicData.crossmintClientId || '';
    // rely on getters to handle the default values
    _clientTheme.value = publicData.clientTheme || {};
  }

  async function refreshOnChainClaim () {
    const onChainData = await contract.value.getClaim(claimType.value);
    total.value = onChainData.total;
    totalMax.value = onChainData.totalMax;
    walletMax.value = onChainData.walletMax;
    startDate.value = onChainData.startDate;
    endDate.value = onChainData.endDate;
    storageProtocol.value = onChainData.storageProtocol;
    merkleRoot.value = onChainData.merkleRoot;
    location.value = onChainData.location;
    tokenId.value = onChainData.tokenId;
    cost.value = onChainData.cost;
  }

  async function fetchMerkleInfo (address: string | undefined) {
    if (!address) {
      return [];
    }
    if (audienceId.value) {
      return await collectors.getMerkleInfosForAudience(
        slug.value,
        address
      );
    } else if (merkleTreeId.value) {
      return await collectors.getMerkleInfos(
        merkleTreeId.value,
        address
      );
    } else {
      return [];
    }
  }

  async function refreshWeb3State () {
    isLoadingWeb3State.value = true;

    try {
      if (hasAllowlist.value) {
        merkleInfo.value = await fetchMerkleInfo(mintForWallet.value);
        const { mintIndices, merkleProofs } = await _fetchMintIndices(merkleInfo.value);
        claimableMintIndices.value = mintIndices;
        claimableMerkleProofs.value = merkleProofs;
      }

      refreshOnChainClaim();
      _refreshNumTokensMintedByWallet();
      _refreshAmountApproved();

      // TODO: how to scope this refresh? identity-widget
      // doesn't need refreshing.
      window.dispatchEvent(new Event('m-refresh-widgets'));
    } finally {
      isLoadingWeb3State.value = false;
    }
  }

  /*
    Check if a certain wallet is eligible to claim one or more tokens
  */
  async function checkWalletsEligibility (addresses: string[]) : Promise<string[]> {
    // only check for eligibility if there's an allowlist (This is constrained on contract-level)
    const eligibleWallets : string[] = [];
    if (walletRestriction.value !== 'allowlist-limit') {
      return eligibleWallets;
    }

    for (const address of addresses) {
      const merkleInfo = await fetchMerkleInfo(address);
      const { mintIndices } = await _fetchMintIndices(merkleInfo);
      const allowedMintQuantity = _getClaimableQuantity(walletRestriction.value, mintIndices);
      if (allowedMintQuantity > 0) {
        eligibleWallets.push(address);
      }
    }
    return eligibleWallets;
  }

  async function _refreshAmountApproved () {
    if (erc20Address.value) {
      erc20ApprovedSpend.value = await erc20Contract.value.getAllowance(extensionAddress.value, walletAddress.value!);
    }
  }

  /**
   * Private Methods
   */

  async function _refreshNumTokensMintedByWallet () {
    if (!mintForWallet.value) {
      return null;
    }

    if (walletRestriction.value === 'allowlist-limit') {
      const availableIndices = merkleInfo.value.map(
        (claimMerkleInfo) => claimMerkleInfo.value
      );
      const usedIndices = await contract.value.checkMintIndices(
        availableIndices
      );

      tokensMintedByWallet.value = usedIndices.filter(
        (mintedIndice) => mintedIndice === true
      ).length;
    } else if (walletRestriction.value === 'wallet-limit') {
      tokensMintedByWallet.value = await contract.value.getTotalMints(
        mintForWallet.value
      );
    } else {
      // claims with no wallet restrictions do not track
      // the amount of tokens minted per wallet.
      tokensMintedByWallet.value = null;
    }
  }

  function _getClaimableQuantity (walletRestriction: WalletRestrictionType, claimableMintIndices: number[]) {
    const totalSupply = totalMax.value === null ? Infinity : totalMax.value;

    // Bound by 0 as can be negative if creator updates total supply post mint
    const availableSupply = Math.max(0, totalSupply - total.value);

    let availableForWallet;
    switch (walletRestriction) {
      case 'allowlist-limit':
        availableForWallet = claimableMintIndices.length;
        break;
      case 'wallet-limit':
        // 1. can be negative if creator updates per wallet limit
        // 2. if we have a wallet limit, we always have a value for tokensMinted
        availableForWallet = Math.max(0, (walletMax.value as number) - tokensMintedByWallet.value!);
        break;
      case 'no-limit':
        availableForWallet = Infinity;
        break;
    }

    return Math.min(availableSupply, availableForWallet);
  }

  async function _fetchMintIndices (merkleInfo: IMerkleInfo[]) {
    if (!hasAllowlist.value) {
      return { mintIndices: [], merkleProofs: [] };
    }

    const mintIndices = merkleInfo.map((claimMerkleInfo) => claimMerkleInfo.value);
    const mintIndicesStatus = await contract.value.checkMintIndices(mintIndices);
    const claimableMerkleInfo = merkleInfo.filter((_, index) => !mintIndicesStatus[index]);

    return {
      mintIndices: claimableMerkleInfo.map((claimMerkleInfo) => claimMerkleInfo.value),
      merkleProofs: claimableMerkleInfo.map((claimMerkleInfo) => claimMerkleInfo.merkleProof)
    };
  }

  function setMintForWallet (address: string) {
    mintForWallet.value = address;
  }

  /**
   * GETTERS
   */
  const hasAllowlist = computed(() => {
    return merkleRoot.value !== formatBytes32String('');
  });

  const isConnected = computed(() => {
    return Boolean(walletAddress.value);
  });

  const hasWalletLimit = computed(() => {
    return walletMax.value !== null;
  });

  const isUnlimitedSupply = computed(() => {
    return totalMax.value === null;
  });

  const targetNetwork = computed(() => {
    // TODO: support isDev override
    // return isDev() ? network.value : networkId.value;
    return networkId.value;
  });

  const status = computed(() => {
    const now = Date.now();

    if (startDate.value && startDate.value.getTime() > now) {
      return 'not-started';
    } else if (endDate.value && endDate.value.getTime() < now) {
      return 'ended';
    } else {
      return 'active';
    }
  });

  const isSoldOut = computed(() => {
    if (totalMax.value === null) {
      return false;
    }

    // total can exceed totalMax if creator updates supply
    return total.value > 0 && total.value >= totalMax.value;
  });

  const isPayable = computed(() => {
    return cost.value.eq(0);
  });

  // getters for clientTheme-specific values with defaults. using object.assign
  // instead of spread since config can be undefined
  const checkoutEmojis = computed<Required<ClientTheme>['checkoutEmojis']>(() => {
    if (!_clientTheme.value.checkoutEmojis || !_clientTheme.value.checkoutEmojis.length) {
      return ['🎉'];
    }
    return _clientTheme.value.checkoutEmojis;
  });

  const clientColors = computed<Required<ClientTheme>['colors']>(() => {
    return Object.assign({
      themePrimary: 'white',
      themeSecondary: 'hsl(0deg 0% 100% / 50%)'
    }, _clientTheme.value.colors);
  });

  const appBackground = computed<Required<ClientTheme>['appBackground']>(() => {
    return Object.assign({
      backgroundColor: 'black',
      image: '',
      video: ''
    }, _clientTheme.value.appBackground);
  });

  const mediaBackground = computed<Required<ClientTheme>['mediaBackground']>(() => {
    // DNE or is empty object
    if (!_clientTheme.value.mediaBackground || Object.keys(_clientTheme.value.mediaBackground).length === 0) {
      return {
        colors: ['transparent']
      };
    }
    return _clientTheme.value.mediaBackground;
  });

  const clientAnimation = computed<Required<Required<ClientTheme>['animation']> | null>(() => {
    if (!_clientTheme.value.animation || !_clientTheme.value.animation.source) {
      return null;
    }
    return Object.assign({ playOnMobile: false }, _clientTheme.value.animation);
  });

  const isFreeClaim = computed(() => {
    return cost.value.eq(0);
  });

  function parseOnChainMetadata (uri: string) {
    let metadata;
    try {
      if (uri.startsWith('data:application/json')) {
        // Handling inline metadata
        if (uri.startsWith('data:application/json;utf8,')) {
          try {
            metadata = JSON.parse(uri.slice(27));
          } catch (error) {
            metadata = JSON.parse(unescape(uri.slice(27)));
          }
        } else if (uri.startsWith('data:application/json;base64,')) {
          const uriDecoded = atob(uri.slice(29));
          try {
            metadata = JSON.parse(uriDecoded);
          } catch (error) {
            metadata = JSON.parse(unescape(uriDecoded));
          }
        } else if (uri.startsWith('data:application/json,')) {
          try {
            metadata = JSON.parse(uri.slice(22));
          } catch (error) {
            metadata = JSON.parse(unescape(uri.slice(22)));
          }
        } else {
          throw new Error('Unknown format for uri');
        }
      }
    } catch (error) {
      // do nothing
    }
    return metadata;
  }

  // TODO: use storage protocol?
  const arweaveURL = computed(() => {
    // Onchain metadata asset
    if (parseOnChainMetadata(location.value)) return '';
    return `https://arweave.net/${location.value}`;
  });

  const onChainImage = computed(() => {
    const onChainMetadata = parseOnChainMetadata(location.value);
    if (onChainMetadata && (onChainMetadata.image || onChainMetadata.image_url)) return onChainMetadata.image || onChainMetadata.image_url;
    return '';
  });

  const is721 = computed(() => {
    return claimType.value === 'erc721';
  });

  const is1155 = computed(() => {
    return claimType.value === 'erc1155';
  });

  /**
   * A claim can be restricted by an allowlist (0x1 is allowed 4 mints)
   * or by wallet limit (ie: walletMax, '2 tokens max per wallet').
   * Otherwise there is no limit.
   *
   * Note: that a claim may still have a supply cap. This just
   * describes the wallet restriction.
   */
  const walletRestriction = computed(() : WalletRestrictionType => {
    if (hasAllowlist.value) {
      return 'allowlist-limit';
    } else if (walletMax.value) {
      return 'wallet-limit';
    } else {
      return 'no-limit';
    }
  });

  /**
   * Returns the number of tokens that can be claimed by the current wallet.
   * This is the minimum of the available supply and the amount available for
   * the wallet.
   *
   * @returns number, including Infinity
   */
  const claimableQuantity = computed(() => {
    return _getClaimableQuantity(walletRestriction.value, claimableMintIndices.value);
  });

  const hasTokensToMint = computed(() => {
    return claimableQuantity.value !== 0;
  });

  const isChainCorrect = computed(() => {
    return targetNetwork.value === activeNetwork.value;
  });

  const hasProcessingFees = computed(() => {
    const traits = EXTENSION_TRAITS[extensionAddress.value];
    return (traits && traits.includes('fee'));
  });

  /**
   * Prices
   */
  const price = computed((): BigNumber => {
    if (isNaN(tokensToMint.value) || !tokensToMint.value) return BigNumber.from(0);
    return cost.value.mul(tokensToMint.value);
  });

  const manifoldFee = computed((): BigNumber => {
    if (isNaN(tokensToMint.value) || !tokensToMint.value) return BigNumber.from(0);
    const feeToUse = hasAllowlist.value ? FEE_PER_MERKLE_MINT : FEE_PER_MINT;
    return feeToUse.mul(tokensToMint.value);
  });

  const totalCost = computed((): BigNumber => {
    if (hasProcessingFees.value) {
      return price.value.add(manifoldFee.value);
    }
    return price.value;
  });

  const priceInUsd = computed((): number => {
    if (price.value.eq(0)) return 0;
    if (!ethToUsdRate.value) return 0;

    if (erc20Address.value) {
      return erc20ToUsdRate.value * +formatUnits(price.value, erc20Decimals.value);
    }
    return ethToUsdRate.value * +formatEther(price.value);
  });

  const manifoldFeeInUsd = computed((): number => {
    if (manifoldFee.value.eq(0)) return 0;
    if (!ethToUsdRate.value) return 0;
    return ethToUsdRate.value * +formatEther(manifoldFee.value);
  });

  const totalCostInUsd = computed((): number => {
    if (totalCost.value.eq(0)) return 0;
    if (!ethToUsdRate.value) return 0;
    if (erc20Address.value) {
      return erc20ToUsdRate.value * +formatUnits(totalCost.value, erc20Decimals.value) + manifoldFeeInUsd.value;
    }
    return ethToUsdRate.value * +formatEther(totalCost.value);
  });

  const hasEnoughEth = computed(() => {
    // Don't block the user if we can't fetch their balance
    if (!balance.value) return true;
    if (erc20Address.value) {
      return erc20ApprovedSpend.value.gte(totalCost.value);
    }
    return balance.value.gt(totalCost.value);
  });

  /**
   * OTHER
   */
  watch(mintForWallet, () => {
    if (!mintForWallet.value || !initialized.value) {
      return;
    }
    refreshWeb3State();
  });

  watch(activeNetwork, () => {
    if (!activeNetwork.value || !initialized.value) {
      return;
    }
    refreshWeb3State();
  });

  watch(eligibleVaultWallets, () => {
    // default to the first eligible vault wallet, if the connected wallet is not eligible
    if (walletAddress.value && eligibleVaultWallets.value && eligibleVaultWallets.value.length > 0 && !eligibleVaultWallets?.value?.includes(walletAddress.value)) {
      setMintForWallet(eligibleVaultWallets.value[0]);
    }
  });

  return {
    initialize,
    fetchMerkleInfo,
    refreshOnChainClaim,
    refreshWeb3State,
    setMintForWallet,
    hasAllowlist,
    walletRestriction,
    isChainCorrect,
    isConnected,
    isUnlimitedSupply,
    hasWalletLimit,
    activeNetwork,
    walletAddress,
    ensOrFormattedWalletAddress,
    creatorEnsOrFormattedWalletAddress,
    balance,
    mintForWallet,
    claimableQuantity,
    tokensToMint,
    price,
    manifoldFee,
    totalCost,
    priceInUsd,
    manifoldFeeInUsd,
    totalCostInUsd,
    hasProcessingFees,
    hasEnoughEth,
    hasTokensToMint,
    isSoldOut,
    status,
    arweaveURL,
    onChainImage,
    initialized,
    tokensMintedByWallet,
    targetNetwork,
    merkleInfo,
    id,
    appId,
    name,
    image,
    description,
    contract,
    slug,
    audienceId,
    merkleTreeId,
    extensionAddress,
    creatorContractAddress,
    claimableMerkleProofs,
    claimableMintIndices,
    claimIndex,
    claimType,
    isPayable,
    canPayWithCard,
    crossmintClientId,
    isCrossmintVerified,
    tokenUrl,
    animation,
    fallbackProvider,
    creator,
    total,
    totalMax,
    walletMax,
    startDate,
    endDate,
    storageProtocol,
    merkleRoot,
    location,
    tokenId,
    cost,
    isFreeClaim,
    is721,
    is1155,
    isProviderAvailable,
    isLoadingWeb3State,
    isDelegationLoading,
    eligibleVaultWallets,
    delegationError,
    checkoutEmojis,
    clientColors,
    appBackground,
    mediaBackground,
    clientAnimation,
    erc20Address,
    erc20Symbol,
    erc20Decimals,
    erc20ApprovedSpend,
    erc20Contract
  };
});

export type State = ReturnType<typeof useClaimStore>;
