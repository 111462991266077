import { TransactionResponse } from '@ethersproject/abstract-provider';
import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { markRaw } from 'vue';
import { ManifoldBridgeProvider } from '@manifoldxyz/manifold-provider-client';
import {
  EXTENSION_ABIS,
  EXTENSION_TRAITS,
  FEE_PER_MERKLE_MINT,
  FEE_PER_MINT,
  FREE_EXTENSION_MAINNET_721,
  FREE_EXTENSION_MAINNET_1155,
  NETWORK_ID
} from '@/common/constants';
import { ClaimType } from '@/store/claimStore';

export enum StorageProtocol {
  INVALID,
  NONE,
  ARWEAVE,
  IPFS,
}

export type Claim = {
  total: number;
  totalMax: number | null;
  walletMax: number | null;
  startDate: Date | null;
  endDate: Date | null;
  storageProtocol: StorageProtocol;
  merkleRoot: string;
  location: string;
  tokenId: BigNumber | null;
  cost: BigNumber;
};

interface ContractError {
  code: string;
  cancelled: boolean;
  replacement: { hash: string };
}

export const StorageProtocolGateway = {
  [StorageProtocol.ARWEAVE]: 'https://arweave.net/',
  [StorageProtocol.IPFS]: 'https://ipfs.io/ipfs/',
  [StorageProtocol.NONE]: '',
  [StorageProtocol.INVALID]: ''
} as const;

class ClaimExtensionContract {
  private extensionContractAddress: string;
  private creatorContractAddress: string;
  private claimIndex: number;

  // Manifold bridge provider instance
  private manifoldBridgeProvider: ManifoldBridgeProvider | undefined;

  constructor (
    extensionContractAddress: string,
    creatorContractAddress: string,
    claimIndex: number
  ) {
    this.extensionContractAddress = extensionContractAddress;
    this.creatorContractAddress = creatorContractAddress;
    this.claimIndex = claimIndex;
  }

  protected _getContractInstance (
    withSigner = false,
    bridge = false,
    unchecked = false
  ): Contract {
    const abi: string[] =
      EXTENSION_ABIS[this.extensionContractAddress.toLowerCase()];
    if (bridge) {
      return new Contract(
        this.extensionContractAddress,
        abi,
        this._getManifoldBridgeProvider()
      );
    }
    const contract = window.ManifoldEthereumProvider.contractInstance(
      this.extensionContractAddress,
      abi,
      withSigner,
      unchecked
    );
    if (!contract) {
      throw new Error(
        'No contract instance available, please refresh this page to try again'
      );
    }
    return contract;
  }

  async getClaim (spec: ClaimType): Promise<Claim> {
    const claimArray = await this._callWeb3WithServerFallback(
      'getClaim',
      [this.creatorContractAddress, this.claimIndex]
    );
    return this.processResult(claimArray, spec);
  }

  async checkMintIndices (
    mintIndices: number[]
  ): Promise<boolean[]> {
    return this._callWeb3WithServerFallback(
      'checkMintIndices',
      [this.creatorContractAddress, this.claimIndex, mintIndices]
    );
  }

  async getTotalMints (walletAddress: string): Promise<number> {
    return this._callWeb3WithServerFallback(
      'getTotalMints',
      [walletAddress, this.creatorContractAddress, this.claimIndex]
    );
  }

  async mint (
    mintIndex: number,
    merkleProofs: string[] | null,
    paymentAmount: BigNumber = BigNumber.from(0),
    walletAddress: string,
    mintForAddress: string
  ): Promise<TransactionResponse> {
    let unchecked = false;
    try {
      // if using wallet connect with no provider, we will use wallet connect built in provider to make write calls
      if (
        localStorage.getItem('connectMethod') &&
        localStorage.getItem('connectMethod') === 'walletConnect'
      ) {
        unchecked = true;
      }

      const traits = EXTENSION_TRAITS[this.extensionContractAddress];

      const feeToUse = merkleProofs ? FEE_PER_MERKLE_MINT : FEE_PER_MINT;
      if (traits && traits.includes('fee')) paymentAmount = paymentAmount.add(feeToUse);

      const gasLimit = await this.estimateGasMint(
        walletAddress,
        mintForAddress,
        mintIndex,
        merkleProofs || [],
        paymentAmount
      );

      if (traits && traits.includes('delegateMint')) {
        return await this._getContractInstance(
          true,
          false,
          unchecked
        ).mint(
          this.creatorContractAddress,
          this.claimIndex,
          mintIndex,
          merkleProofs || [],
          mintForAddress,
          {
            value: paymentAmount,
            gasLimit
          }
        );
      } else {
        return await this._getContractInstance(
          true,
          false,
          unchecked
        ).mint(
          this.creatorContractAddress,
          this.claimIndex,
          mintIndex,
          merkleProofs || [],
          {
            value: paymentAmount,
            gasLimit
          }
        );
      }
    } catch (e: any) {
      return await this.errorHandling(e);
    }
  }

  async mintBatch (
    mintCount: number,
    mintIndices: number[],
    merkleProofs: string[][],
    paymentAmount: BigNumber = BigNumber.from(0),
    walletAddress: string,
    mintForAddress: string
  ): Promise<TransactionResponse> {
    let unchecked = false;
    try {
      // if using wallet connect with no provider, we will use wallet connect built in provider to make write calls
      if (
        localStorage.getItem('connectMethod') &&
        localStorage.getItem('connectMethod') === 'walletConnect'
      ) {
        unchecked = true;
      }

      const traits = EXTENSION_TRAITS[this.extensionContractAddress];

      const feeToUse = (merkleProofs.length !== 0) ? FEE_PER_MERKLE_MINT : FEE_PER_MINT;
      if (traits && traits.includes('fee')) paymentAmount = paymentAmount.add(feeToUse.mul(mintCount));

      const gasLimit = await this.estimateGasBatchMint(
        walletAddress,
        mintForAddress,
        mintCount,
        mintIndices,
        merkleProofs,
        paymentAmount
      );

      if (traits && traits.includes('delegateMint')) {
        return await this._getContractInstance(
          true,
          false,
          unchecked
        ).mintBatch(
          this.creatorContractAddress,
          this.claimIndex,
          mintCount,
          mintIndices,
          merkleProofs,
          mintForAddress,
          {
            value: paymentAmount,
            gasLimit
          }
        );
      } else {
        return await this._getContractInstance(
          true,
          false,
          unchecked
        ).mintBatch(
          this.creatorContractAddress,
          this.claimIndex,
          mintCount,
          mintIndices,
          merkleProofs,
          {
            value: paymentAmount,
            gasLimit
          }
        );
      }
    } catch (e: any) {
      return await this.errorHandling(e);
    }
  }

  async estimateGasMint (
    walletAddress: string,
    mintForAddress: string,
    mintIndex: number,
    merkleProofs: string[],
    paymentAmount: BigNumber = BigNumber.from(0)
  ): Promise<BigNumber> {
    const traits = EXTENSION_TRAITS[this.extensionContractAddress];
    let args;
    let functionSig = 'mint(address,uint256,uint32,bytes32[])';
    if (traits && traits.includes('delegateMint')) {
      args = [
        this.creatorContractAddress,
        this.claimIndex,
        mintIndex,
        merkleProofs,
        mintForAddress,
        {
          from: walletAddress,
          value: paymentAmount
        }
      ];
      functionSig = 'mint(address,uint256,uint32,bytes32[],address)';
    } else {
      args = [
        this.creatorContractAddress,
        this.claimIndex,
        mintIndex,
        merkleProofs,
        {
          from: walletAddress,
          value: paymentAmount
        }
      ];
    }

    return this._estimateGas3WithServerFallback(functionSig, args);
  }

  async estimateGasBatchMint (
    walletAddress: string,
    mintForAddress: string,
    mintCount: number,
    mintIndices: number[],
    merkleProofs: string[][],
    paymentAmount: BigNumber = BigNumber.from(0)
  ): Promise<BigNumber> {
    const traits = EXTENSION_TRAITS[this.extensionContractAddress];
    let args;
    let functionSig = 'mintBatch(address,uint256,uint16,uint32[],bytes32[][])';
    if (traits && traits.includes('delegateMint')) {
      args = [
        this.creatorContractAddress,
        this.claimIndex,
        mintCount,
        mintIndices,
        merkleProofs,
        mintForAddress,
        {
          from: walletAddress,
          value: paymentAmount
        }
      ];
      functionSig =
        'mintBatch(address,uint256,uint16,uint32[],bytes32[][],address)';
    } else {
      args = [
        this.creatorContractAddress,
        this.claimIndex,
        mintCount,
        mintIndices,
        merkleProofs,
        {
          from: walletAddress,
          value: paymentAmount
        }
      ];
    }
    return this._estimateGas3WithServerFallback(functionSig, args);
  }

  async _estimateGas3WithServerFallback (
    functionSig: string,
    args: any[]
  ): Promise<BigNumber> {
    if (!window.ManifoldEthereumProvider.chainIsCorrect()) throw new Error('Wrong Network');
    let gasEstimate;
    try {
      gasEstimate = await this._getContractInstance(true).estimateGas[
        functionSig
      ](...args);
    } catch (e) {
      // get etimate from manifold bridge instead
      gasEstimate = await this._getContractInstance(
        true,
        true
      ).estimateGas[functionSig](...args);
    }

    // Multiply gas estimate by 1.25 to account for inaccurate estimates from Metamask.
    gasEstimate = gasEstimate.mul((1 + 0.25) * 100).div(100);
    return gasEstimate;
  }

  async _callWeb3WithServerFallback (
    functionName: string,
    args: any[]
  ): Promise<any> {
    const provider = window.ManifoldEthereumProvider.provider();
    // @ts-ignore
    if (!provider) {
      // No available provider failure scenario, use the server endpoint
      return this._getContractInstance(false, true)[functionName](
        ...args
      );
    }
    try {
      // We have a web3timeout race because there are certain situations where
      // web3 requests will hang.  e.g. Safari websockets or Infura rate limiting
      // ref: https://developer.apple.com/forums/thread/679576
      // res: https://github.com/tilt-dev/tilt/issues/4746

      const web3timeout = new Promise(resolve => setTimeout(resolve, 1500));
      // eslint-disable-next-line no-async-promise-executor
      const web3result = new Promise(async (resolve) => {
        try {
          resolve(await this._getContractInstance(false)[functionName](...args));
        } catch {
          resolve(undefined);
        }
      });
      let result: any = await Promise.race([web3timeout, web3result]);
      if (result === undefined) {
        // Fallback provider failure scenario, use the server endpoint
        result = await this._getContractInstance(false, true)[
          functionName
        ](...args);
      }
      return result;
    } catch (e) {
      // try getting from server instead
      return await this._getContractInstance(false, true)[functionName](
        ...args
      );
    }
  }

  processResult (claimArray: Array<any>, spec: ClaimType): Claim {
    const convertDate = (unixSeconds: number) => {
      if (unixSeconds === 0) {
        return null;
      } else {
        return new Date(unixSeconds * 1000);
      }
    };

    if (spec.toLowerCase() === 'erc721') {
      if (
        this.extensionContractAddress?.toLowerCase() ===
        FREE_EXTENSION_MAINNET_721
      ) {
        return {
          total: claimArray[0],
          totalMax: claimArray[1] === 0 ? null : claimArray[1],
          walletMax: claimArray[2] === 0 ? null : claimArray[2],
          startDate: convertDate(claimArray[3]),
          endDate: convertDate(claimArray[4]),
          storageProtocol: claimArray[5],
          merkleRoot: claimArray[7],
          location: claimArray[8],
          tokenId: null,
          cost: BigNumber.from(0)
        };
      } else if (EXTENSION_TRAITS[this.extensionContractAddress]?.includes('contractVersion')) {
        return {
          total: claimArray[0],
          totalMax: claimArray[1] === 0 ? null : claimArray[1],
          walletMax: claimArray[2] === 0 ? null : claimArray[2],
          startDate: convertDate(claimArray[3]),
          endDate: convertDate(claimArray[4]),
          storageProtocol: claimArray[5],
          // contractVersion: claimArray[6],
          // identical: claimArray[7],
          merkleRoot: claimArray[8],
          location: claimArray[9],
          tokenId: null,
          cost: BigNumber.from(claimArray[10])
        };
      } else {
        return {
          total: claimArray[0],
          totalMax: claimArray[1] === 0 ? null : claimArray[1],
          walletMax: claimArray[2] === 0 ? null : claimArray[2],
          startDate: convertDate(claimArray[3]),
          endDate: convertDate(claimArray[4]),
          storageProtocol: claimArray[5],
          merkleRoot: claimArray[7],
          location: claimArray[8],
          tokenId: null,
          cost: BigNumber.from(claimArray[9])
        };
      }
    } else {
      // 1155
      if (
        this.extensionContractAddress?.toLowerCase() ===
        FREE_EXTENSION_MAINNET_1155
      ) {
        return {
          total: claimArray[0],
          totalMax: claimArray[1] === 0 ? null : claimArray[1],
          walletMax: claimArray[2] === 0 ? null : claimArray[2],
          startDate: convertDate(claimArray[3]),
          endDate: convertDate(claimArray[4]),
          storageProtocol: claimArray[5],
          merkleRoot: claimArray[6],
          location: claimArray[7],
          tokenId: null,
          cost: BigNumber.from(0)
        };
      } else {
        return {
          total: claimArray[0],
          totalMax: claimArray[1] === 0 ? null : claimArray[1],
          walletMax: claimArray[2] === 0 ? null : claimArray[2],
          startDate: convertDate(claimArray[3]),
          endDate: convertDate(claimArray[4]),
          storageProtocol: claimArray[5],
          merkleRoot: claimArray[6],
          location: claimArray[7],
          tokenId: claimArray[8],
          cost: BigNumber.from(claimArray[9])
        };
      }
    }
  }

  /**
   * Get the manifold bridge provider instance
   */
  private _getManifoldBridgeProvider (): ManifoldBridgeProvider {
    if (!this.manifoldBridgeProvider) {
      this.manifoldBridgeProvider = markRaw(
        new ManifoldBridgeProvider(window.ManifoldEthereumProvider.network() ?? NETWORK_ID)
      );
    }
    return this.manifoldBridgeProvider;
  }

  async errorHandling (error: ContractError) {
    if (
      error.code === 'TRANSACTION_REPLACED' &&
      !error.cancelled &&
      error.replacement
    ) {
      const provider = window.ManifoldEthereumProvider.provider();
      if (!provider) {
        throw new Error(
          'No web3 provider detected, please refresh the page and try again'
        );
      }
      return await provider.getTransaction(error.replacement.hash);
    } else {
      throw error;
    }
  }
}

export default ClaimExtensionContract;
