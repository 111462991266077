import '@/scss/claim.scss';
import { MClaimBuyOnly, MClaimComplete } from '@/exports/';
import { renderComponentWithApp } from '../build/mount';

interface ExportedWidgetMetadata {
  component: any;
}
interface ExportedWidgetMetadataMap {
  [key: string]: ExportedWidgetMetadata;
}

const Widgets: ExportedWidgetMetadataMap = {
  'm-claim-complete': {
    component: MClaimComplete
  },
  'm-claim-buy-only': {
    component: MClaimBuyOnly
  }
};

// track app-component Vue instances for proper destroying later
const renderedComponents = new Map();
let widgetAttributeChangeObserver: MutationObserver;
let bodyChangeObserver: MutationObserver;

const main = (): void => {
  // MutationObserver gets reused by every rendered component
  widgetAttributeChangeObserver = new window.MutationObserver(handleWidgetAttributeChange);

  // manually sweep the DOM for pre-existing widget divs that need replacing
  const elements = Object.keys(Widgets)
    .map((dataWidgetName) => {
      return [...document.querySelectorAll(`[data-widget=${dataWidgetName}]`)];
    })
    .flat();

  elements.forEach((el: Element) => {
    replaceWithWidget(el as HTMLElement);
  });

  // listen for new widget divs being added to the document that need replacing
  const bodyNode = document.querySelector('body');
  if (bodyNode && !bodyChangeObserver) {
    const config = {
      childList: true,
      subtree: true
    };
    bodyChangeObserver = new window.MutationObserver(handleDynamicDivs);
    bodyChangeObserver.observe(bodyNode, config);
  }
};

const getAllChildren = (htmlElement: Element): Element[] => {
  if (!htmlElement.children || htmlElement.children?.length === 0) {
    return [];
  }

  const allChildElements = [];

  for (let i = 0; i < htmlElement.children.length; i++) {
    // include the current child
    allChildElements.push(htmlElement.children[i]);
    const grandChildren = getAllChildren(htmlElement.children[i]);
    if (grandChildren) allChildElements.push(...grandChildren);
  }

  return allChildElements;
};

/* Mutation Handlers */

const handleWidgetAttributeChange = async (mutations: MutationRecord[]) => {
  mutations.forEach((mutation) => {
    if (mutation.type === 'attributes' && mutation.attributeName !== 'data-v-app') {
      // destroy pre-existing app-component before replacing it with new one
      destroyPotentialWidget(mutation.target as HTMLElement);
      replaceWithWidget(mutation.target as HTMLElement);
    }
  });
};

const handleDynamicDivs = async (mutations: MutationRecord[]) => {
  mutations.forEach((mutation) => {
    mutation.addedNodes.forEach((node) => {
      const htmlEl = node as HTMLElement;

      if (isRecognisedWidgetName(htmlEl?.dataset?.widget)) {
        replaceWithWidget(htmlEl);
        return;
      }

      const children = getAllChildren(htmlEl);
      children.forEach((child) => {
        if (isRecognisedWidgetName((child as HTMLElement)?.dataset?.widget)) {
          replaceWithWidget(child as HTMLElement);
        }
      });
    });

    mutation.removedNodes.forEach((node) => {
      const htmlEl = node as HTMLElement;

      if (isRecognisedWidgetName(htmlEl?.dataset?.widget)) {
        destroyPotentialWidget(htmlEl);
        return;
      }

      const children = getAllChildren(htmlEl);
      children.forEach((child) => {
        if (isRecognisedWidgetName((child as HTMLElement)?.dataset?.widget)) {
          destroyPotentialWidget(child as HTMLElement);
        }
      });
    });
  });
};

function isRecognisedWidgetName (name?: string): boolean {
  const stringName = `${name}`;
  if (!stringName) {
    return false;
  }

  return !!Widgets[stringName];
}

function replaceWithWidget (el: HTMLElement) {
  // grab the DOM element's data- properties to use as propsData
  const data = el.dataset;
  // would only get this far if the data-widget attribute is a DataWidgetName
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const widgetName = data.widget!;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const id = el.getAttribute('data-id') ? parseInt(el.getAttribute('data-id')!) : (
    process?.env?.VUE_APP_INSTANCE_ID ? parseInt(process.env.VUE_APP_INSTANCE_ID) : undefined
  );
  const fallbackProvider = el.getAttribute('data-fallback-provider') ? el.getAttribute('data-fallback-provider') : undefined;

  const propsData = {
    id: id,
    fallbackProvider: fallbackProvider
  };

  const renderedComponent = renderComponentWithApp({
    el: el,
    component: Widgets[widgetName].component,
    props: propsData
  });
  renderedComponents.set(el, renderedComponent);

  // observe any attribute changes and rerender accordingly
  const config = {
    attributes: true,
    childList: false,
    subtree: false
  };
  widgetAttributeChangeObserver.observe(el, config);
}

/*
 * Checks if the el has a corresponding rendered Vue component in memory.
 * If it does, we unmount the Vue component and destroy its data in memory.
 */
const destroyPotentialWidget = (el: HTMLElement) => {
  const renderedComponentRef = renderedComponents.get(el);
  if (renderedComponentRef) {
    // unmount and destroy the pre-existing Vue app-component for memory's sake
    renderedComponentRef();
    renderedComponents.delete(el);
  }
};

// @dev: This package is super useful but doesn't work as an NPM module so this is the best we can do, same code is used for Burn and redeem app also
const injectGoogleModelViewer = () => {
  if (document) {
    const alreadyInjected = document.querySelector(
      'script[src*="https://unpkg.com/@google/model-viewer"]'
    );
    if (alreadyInjected) {
      return;
    }
    const script = document.createElement('script');
    script.src =
      'https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js';
    script.type = 'module';
    document.head.appendChild(script);
  }
};

/* Entry */
if (window) {
  injectGoogleModelViewer();

  // ref: https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming/loadEventEnd
  const alreadyLoaded = performance
    .getEntriesByType('navigation')
    .every((e) => (e as PerformanceNavigationTiming).loadEventEnd);

  if (alreadyLoaded) {
    // the `load` event already fired so we're ready to do main() instantly
    main();
  } else {
    // we only want to call main() after the page `load` fires
    window.addEventListener('load', main);
  }
}
