const abi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'delegationRegistry',
        type: 'address'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'creatorContract',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'claimIndex',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'initializer',
        type: 'address'
      }
    ],
    name: 'ClaimInitialized',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'creatorContract',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'claimIndex',
        type: 'uint256'
      }
    ],
    name: 'ClaimMint',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'creatorContract',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'claimIndex',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint16',
        name: 'mintCount',
        type: 'uint16'
      }
    ],
    name: 'ClaimMintBatch',
    type: 'event'
  },
  {
    inputs: [],
    name: 'DELEGATION_REGISTRY',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'creatorContractAddress',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'claimIndex',
        type: 'uint256'
      },
      {
        internalType: 'address[]',
        name: 'recipients',
        type: 'address[]'
      },
      {
        internalType: 'uint256[]',
        name: 'amounts',
        type: 'uint256[]'
      }
    ],
    name: 'airdrop',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'creatorContractAddress',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'claimIndex',
        type: 'uint256'
      },
      {
        internalType: 'uint32',
        name: 'mintIndex',
        type: 'uint32'
      }
    ],
    name: 'checkMintIndex',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'creatorContractAddress',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'claimIndex',
        type: 'uint256'
      },
      {
        internalType: 'uint32[]',
        name: 'mintIndices',
        type: 'uint32[]'
      }
    ],
    name: 'checkMintIndices',
    outputs: [
      {
        internalType: 'bool[]',
        name: 'minted',
        type: 'bool[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'creatorContractAddress',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'claimIndex',
        type: 'uint256'
      }
    ],
    name: 'getClaim',
    outputs: [
      {
        components: [
          {
            internalType: 'uint32',
            name: 'total',
            type: 'uint32'
          },
          {
            internalType: 'uint32',
            name: 'totalMax',
            type: 'uint32'
          },
          {
            internalType: 'uint32',
            name: 'walletMax',
            type: 'uint32'
          },
          {
            internalType: 'uint48',
            name: 'startDate',
            type: 'uint48'
          },
          {
            internalType: 'uint48',
            name: 'endDate',
            type: 'uint48'
          },
          {
            internalType: 'enum IERC1155LazyPayableClaim.StorageProtocol',
            name: 'storageProtocol',
            type: 'uint8'
          },
          {
            internalType: 'bytes32',
            name: 'merkleRoot',
            type: 'bytes32'
          },
          {
            internalType: 'string',
            name: 'location',
            type: 'string'
          },
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'cost',
            type: 'uint256'
          },
          {
            internalType: 'address payable',
            name: 'paymentReceiver',
            type: 'address'
          }
        ],
        internalType: 'struct IERC1155LazyPayableClaim.Claim',
        name: 'claim',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'minter',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'creatorContractAddress',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'claimIndex',
        type: 'uint256'
      }
    ],
    name: 'getTotalMints',
    outputs: [
      {
        internalType: 'uint32',
        name: '',
        type: 'uint32'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'creatorContractAddress',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'claimIndex',
        type: 'uint256'
      },
      {
        components: [
          {
            internalType: 'uint32',
            name: 'totalMax',
            type: 'uint32'
          },
          {
            internalType: 'uint32',
            name: 'walletMax',
            type: 'uint32'
          },
          {
            internalType: 'uint48',
            name: 'startDate',
            type: 'uint48'
          },
          {
            internalType: 'uint48',
            name: 'endDate',
            type: 'uint48'
          },
          {
            internalType: 'enum IERC1155LazyPayableClaim.StorageProtocol',
            name: 'storageProtocol',
            type: 'uint8'
          },
          {
            internalType: 'bytes32',
            name: 'merkleRoot',
            type: 'bytes32'
          },
          {
            internalType: 'string',
            name: 'location',
            type: 'string'
          },
          {
            internalType: 'uint256',
            name: 'cost',
            type: 'uint256'
          },
          {
            internalType: 'address payable',
            name: 'paymentReceiver',
            type: 'address'
          }
        ],
        internalType: 'struct IERC1155LazyPayableClaim.ClaimParameters',
        name: 'claimParameters',
        type: 'tuple'
      }
    ],
    name: 'initializeClaim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'creatorContractAddress',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'claimIndex',
        type: 'uint256'
      },
      {
        internalType: 'uint32',
        name: 'mintIndex',
        type: 'uint32'
      },
      {
        internalType: 'bytes32[]',
        name: 'merkleProof',
        type: 'bytes32[]'
      },
      {
        internalType: 'address',
        name: 'mintFor',
        type: 'address'
      }
    ],
    name: 'mint',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'creatorContractAddress',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'claimIndex',
        type: 'uint256'
      },
      {
        internalType: 'uint16',
        name: 'mintCount',
        type: 'uint16'
      },
      {
        internalType: 'uint32[]',
        name: 'mintIndices',
        type: 'uint32[]'
      },
      {
        internalType: 'bytes32[][]',
        name: 'merkleProofs',
        type: 'bytes32[][]'
      },
      {
        internalType: 'address',
        name: 'mintFor',
        type: 'address'
      }
    ],
    name: 'mintBatch',
    outputs: [],
    stateMutability: 'payable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'bytes4',
        name: 'interfaceId',
        type: 'bytes4'
      }
    ],
    name: 'supportsInterface',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'creatorContractAddress',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256'
      }
    ],
    name: 'tokenURI',
    outputs: [
      {
        internalType: 'string',
        name: 'uri',
        type: 'string'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'creatorContractAddress',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'claimIndex',
        type: 'uint256'
      },
      {
        components: [
          {
            internalType: 'uint32',
            name: 'totalMax',
            type: 'uint32'
          },
          {
            internalType: 'uint32',
            name: 'walletMax',
            type: 'uint32'
          },
          {
            internalType: 'uint48',
            name: 'startDate',
            type: 'uint48'
          },
          {
            internalType: 'uint48',
            name: 'endDate',
            type: 'uint48'
          },
          {
            internalType: 'enum IERC1155LazyPayableClaim.StorageProtocol',
            name: 'storageProtocol',
            type: 'uint8'
          },
          {
            internalType: 'bytes32',
            name: 'merkleRoot',
            type: 'bytes32'
          },
          {
            internalType: 'string',
            name: 'location',
            type: 'string'
          },
          {
            internalType: 'uint256',
            name: 'cost',
            type: 'uint256'
          },
          {
            internalType: 'address payable',
            name: 'paymentReceiver',
            type: 'address'
          }
        ],
        internalType: 'struct IERC1155LazyPayableClaim.ClaimParameters',
        name: 'claimParameters',
        type: 'tuple'
      }
    ],
    name: 'updateClaim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'creatorContractAddress',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'claimIndex',
        type: 'uint256'
      },
      {
        internalType: 'enum IERC1155LazyPayableClaim.StorageProtocol',
        name: 'storageProtocol',
        type: 'uint8'
      },
      {
        internalType: 'string',
        name: 'location',
        type: 'string'
      }
    ],
    name: 'updateTokenURIParams',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
];

export default abi;
