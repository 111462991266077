export async function getEthToUsdRate (): Promise<number | undefined> {
  try {
    const x = await fetch('https://api.coinbase.com/v2/prices/ETH-USD/spot', {
      method: 'GET'
    });
    if (x.status !== 200) {
      throw new Error('Non-200 response from coinbase');
    }
    const ethToUsdJson = await x.json();
    return parseFloat(ethToUsdJson.data.amount);
  } catch {
    return undefined;
  }
}

export async function getERC20ToUSDRate (erc20: string): Promise<number | undefined> {
  if (erc20.toLowerCase() === 'eth') return undefined;
  try {
    const x = await fetch(`https://api.coingecko.com/api/v3/coins/${erc20.toLowerCase()}`, {
      method: 'GET'
    });
    if (x.status !== 200) {
      throw new Error('Non-200 response from coinbase');
    }
    const erc20ToUSD = await x.json();
    return parseFloat(erc20ToUSD?.market_data?.current_price?.usd ?? 0);
  } catch {
    return undefined;
  }
}
