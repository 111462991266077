import { BigNumber } from '@ethersproject/bignumber';
import {
  claim721Abi,
  claim721PayableAbi,
  claim1155Abi,
  claim1155PayableAbi
} from '@/abis';
import claimABI721Airdrop from '@/abis/claim-abi-721-airdrop';
import claimABI721Fee from '@/abis/claim-abi-721-fee';
import claimABI721Optimization from '@/abis/claim-abi-721-optimization';
import claimABI1155Airdrop from '@/abis/claim-abi-1155-airdrop';
import claimABI1155Fee from '@/abis/claim-abi-1155-fee';
import claimABI1155Optimization from '@/abis/claim-abi-1155-optimization';
import isDev from '@/lib/isDev';

export const NETWORK_ID = isDev() ? 5 : 1;

// events
export const AUTHENTICATED = 'm-authenticated';
export const UNAUTHENTICATED = 'm-unauthenticated';
export const MANIFOLD_LOAD_REQUEST = 'm-refresh-widgets';

export const MAX_MINT_PAYABLE = 10;
export const MAX_MINT_FREE = 10;

export const FEE_PER_MINT = BigNumber.from(500000000000000); // 0.0005 ETH * 1e18;
export const FEE_PER_MERKLE_MINT = BigNumber.from(690000000000000); // 0.00069 ETH * 1e18;

export const MANIFOLD_FEE_EXPLANATION = 'Manifold charges a minimal fee for items collected through our apps while creators retain 100% of the revenue. This fee supports ongoing maintenance and future development.';

export const PROCESSING_FEES_EXPLANATION = 'Processing fees include Ethereum network expenses and card payment fees, helping to maintain a secure and efficient user experience. This includes vital components such as fraud protection, transaction monitoring, currency exchange risk management, and responsive customer support.';

export const ETHEREUM_NETWORK_COLORS: Record<number, string> = {
  1: '#59B3AE',
  3: '#EC5A8C',
  4: '#EFC45B',
  5: '#4D99EB',
  42: '#886BF6',
  137: '#7B4ADD',
  6284: '#4D99EB'
};

export const ETHEREUM_NETWORK_NAMES: Record<number, string> = {
  1: 'MAINNET',
  3: 'ROPSTEN',
  4: 'RINKEBY',
  5: 'GOERLI',
  42: 'KOVAN',
  137: 'MATIC',
  6284: 'GOERLI'
};

export const WALLET_LOCAL_STORAGE_KEY = 'connectedAddress';
export const JWT_LOCAL_STORAGE_KEY = 'manifoldOAuth';
export const PREVIEW_PATH = '/c/manifold-campaign-preview';

// Heads up these must all be lowercase
export const FREE_EXTENSION_MAINNET_721 = '0xb08aa31cc2b8c0582be42d38bb643292e0a4b9eb';
export const FREE_EXTENSION_MAINNET_1155 = '0xca71c5270eff44eb6d813a92c0ba12577bddf208';

export const PAID_EXTENSION_GOERLI_721 = '0xff5a6f7f36764aad301b7c9e85a5277614df5e26';
export const PAID_EXTENSION_MAINNET_721 = '0x5ebfd58c5ead1025755f7490e510ccf2c0b4a444';
export const PAID_EXTENSION_GOERLI_1155 = '0xbea4a16cc7e5efbf832a6082fd4e44693264b63f';
export const PAID_EXTENSION_MAINNET_1155 = '0x4e32004d8b81847a670b4a1778ace4dcf2bba01e';

export const AIRDROP_EXTENSION_GOERLI_721 = '0xc3ba6907a64d63a28e5be8bff9c6c1ead7ce7ea7';
export const AIRDROP_EXTENSION_MAINNET_721 = '0xa46f952645d4deec07a7cd98d1ec9ec888d4b61e';
export const AIRDROP_EXTENSION_GOERLI_1155 = '0xa402f5e27b5da8d6593f6bede97be5820529d64a';
export const AIRDROP_EXTENSION_MAINNET_1155 = '0x44e94034afce2dd3cd5eb62528f239686fc8f162';

export const FEE_EXTENSION_GOERLI_721 = '0x6910d1fb4bce932689743457837c085963fe7e2b';
export const FEE_EXTENSION_MAINNET_721 = '0x7581871e1c11f85ec7f02382632b8574fad11b22';
export const FEE_EXTENSION_GOERLI_1155 = '0x11f412403fb8f5cc7d1947763a2d86543103a601';
export const FEE_EXTENSION_MAINNET_1155 = '0xe7d3982e214f9dfd53d23a7f72851a7044072250';

export const OPTIMIZATION_EXTENSION_GOERLI_721 = '0x5294d02a23b193a7dccf8435859e7749bc9456aa';
export const OPTIMIZATION_EXTENSION_MAINNET_721 = '0x3b8c2feb0f4953870f825df64322ec967aa26b8c';
export const OPTIMIZATION_EXTENSION_GOERLI_1155 = '0x73ca7420625d312d1792cea60ced7b35d009322c';
export const OPTIMIZATION_EXTENSION_MAINNET_1155 = '0xdb8d79c775452a3929b86ac5deab3e9d38e1c006';

export const EXTENSION_TRAITS: { [_: string]: string[] } = {
  [FREE_EXTENSION_MAINNET_721]: [],
  [FREE_EXTENSION_MAINNET_1155]: [],

  [PAID_EXTENSION_GOERLI_721]: [],
  [PAID_EXTENSION_GOERLI_1155]: [],
  [PAID_EXTENSION_MAINNET_721]: [],
  [PAID_EXTENSION_MAINNET_1155]: [],

  [AIRDROP_EXTENSION_GOERLI_721]: ['delegateMint'],
  [AIRDROP_EXTENSION_GOERLI_1155]: ['delegateMint'],
  [AIRDROP_EXTENSION_MAINNET_721]: ['delegateMint'],
  [AIRDROP_EXTENSION_MAINNET_1155]: ['delegateMint'],

  [FEE_EXTENSION_GOERLI_721]: ['delegateMint', 'fee'],
  [FEE_EXTENSION_GOERLI_1155]: ['delegateMint', 'fee'],
  [FEE_EXTENSION_MAINNET_721]: ['delegateMint', 'fee'],
  [FEE_EXTENSION_MAINNET_1155]: ['delegateMint', 'fee'],

  [OPTIMIZATION_EXTENSION_GOERLI_721]: ['delegateMint', 'fee', 'contractVersion'],
  [OPTIMIZATION_EXTENSION_GOERLI_1155]: ['delegateMint', 'fee', 'contractVersion'],
  [OPTIMIZATION_EXTENSION_MAINNET_721]: ['delegateMint', 'fee', 'contractVersion'],
  [OPTIMIZATION_EXTENSION_MAINNET_1155]: ['delegateMint', 'fee', 'contractVersion']
};

export const EXTENSION_ABIS: { [_: string]: any[] } = {
  [FREE_EXTENSION_MAINNET_721]: claim721Abi,
  [FREE_EXTENSION_MAINNET_1155]: claim1155Abi,

  [PAID_EXTENSION_GOERLI_721]: claim721PayableAbi,
  [PAID_EXTENSION_MAINNET_721]: claim721PayableAbi,
  [PAID_EXTENSION_GOERLI_1155]: claim1155PayableAbi,
  [PAID_EXTENSION_MAINNET_1155]: claim1155PayableAbi,

  [AIRDROP_EXTENSION_GOERLI_721]: claimABI721Airdrop,
  [AIRDROP_EXTENSION_MAINNET_721]: claimABI721Airdrop,
  [AIRDROP_EXTENSION_GOERLI_1155]: claimABI1155Airdrop,
  [AIRDROP_EXTENSION_MAINNET_1155]: claimABI1155Airdrop,

  [FEE_EXTENSION_GOERLI_721]: claimABI721Fee,
  [FEE_EXTENSION_MAINNET_721]: claimABI721Fee,
  [FEE_EXTENSION_GOERLI_1155]: claimABI1155Fee,
  [FEE_EXTENSION_MAINNET_1155]: claimABI1155Fee,

  [OPTIMIZATION_EXTENSION_GOERLI_721]: claimABI721Optimization,
  [OPTIMIZATION_EXTENSION_MAINNET_721]: claimABI721Optimization,
  [OPTIMIZATION_EXTENSION_GOERLI_1155]: claimABI1155Optimization,
  [OPTIMIZATION_EXTENSION_MAINNET_1155]: claimABI1155Optimization
};

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';

export const DELEGATION_REGISTRY_ADDRESS = '0x00000000000076a84fef008cdabe6409d2fe638b';

export const EXTENSION_CROSSMINT_CLIENT_IDS: { [_: string]: string } = {
  [FREE_EXTENSION_MAINNET_721]: '',
  [FREE_EXTENSION_MAINNET_1155]: '',

  [PAID_EXTENSION_GOERLI_721]: '',
  [PAID_EXTENSION_MAINNET_721]: '',
  [PAID_EXTENSION_GOERLI_1155]: '',
  [PAID_EXTENSION_MAINNET_1155]: '',

  [AIRDROP_EXTENSION_GOERLI_721]: '',
  [AIRDROP_EXTENSION_MAINNET_721]: '',
  [AIRDROP_EXTENSION_GOERLI_1155]: '',
  [AIRDROP_EXTENSION_MAINNET_1155]: '',

  [FEE_EXTENSION_GOERLI_721]: '730423bd-39ff-47ec-bbc9-015c9eb1ba4b',
  [FEE_EXTENSION_MAINNET_721]: '9c152ce0-cb0b-43c6-97d1-31c14cf12c84',
  [FEE_EXTENSION_GOERLI_1155]: '027d28b3-ad2a-4a7a-957f-a56ee7c05262',
  [FEE_EXTENSION_MAINNET_1155]: 'b03341ea-2650-46e8-9015-c9e931223b46',

  [OPTIMIZATION_EXTENSION_GOERLI_721]: 'eddd740b-6ea0-429a-8cee-8718c35b0488',
  [OPTIMIZATION_EXTENSION_GOERLI_1155]: 'f4aeef85-7689-48b6-9191-9a3798d40d41',
  [OPTIMIZATION_EXTENSION_MAINNET_721]: 'c7883670-e5d3-4298-8511-1e3f1a2fd31a',
  [OPTIMIZATION_EXTENSION_MAINNET_1155]: '34f06abd-a6d1-4999-b553-eb99127b1f3c'
};

export const CROSSMINT_EMAIL_LOCAL_STORAGE_KEY = 'crossmint-email';
export const CROSSMINT_MINTFOR_LOCAL_STORAGE_KEY = 'crossmint-wallet';
