import { isRef, Ref, ref, unref, watchEffect } from 'vue';
import { DelegationRegistryContract } from '@/classes/delegationRegistryContract';

export interface IDelegatesOptions {
  checkWalletsEligibility?: (address: string[]) => Promise<string[]>,
  checkAll?: boolean,
}

export function useDelegates (delegatedWallet: Ref<string | undefined> | string | undefined, delegationRegistryAddress: string, options: IDelegatesOptions) {
  const isLoading = ref(false);
  const isError = ref(false);
  const error = ref();
  const eligibleVaultWallets = ref<string[]>();

  async function getWalletEligibility () {
    if (!delegatedWallet || !unref(delegatedWallet)) {
      // delegatedWallet is supposed to eventually be truthly
      return;
    }
    isLoading.value = true;
    try {
      const delegationRegistryContract = new DelegationRegistryContract(
        delegationRegistryAddress
      );
      const delegates = await delegationRegistryContract.getDelegationsByDelegate(unref(delegatedWallet as string));
      const vaultWallets = delegates.map((delegation) => delegation.vault);
      if (options.checkWalletsEligibility) {
        if (options.checkAll) {
          vaultWallets.push(unref(delegatedWallet as string));
        }
        eligibleVaultWallets.value = await options.checkWalletsEligibility(vaultWallets);
      }
    } catch (e) {
      isError.value = true;
      error.value = e;
    } finally {
      isLoading.value = false;
    }
  }

  if (isRef(delegatedWallet)) {
    // setup reactive re-fetch if input delegatedWallet is a ref
    watchEffect(getWalletEligibility);
  } else {
    getWalletEligibility();
  }

  return { isLoading, isError, error, eligibleVaultWallets };
}
