export enum CrossmintVerificationStatus {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
  UNDER_REVIEW = 'under_review',
  FAILED = 'failed'
}

export interface CrossmintVerificationResults {
  verificationStatus: {
    seller: {
      status: CrossmintVerificationStatus;
    },
    collection: {
      status: CrossmintVerificationStatus;
    }
  };
}

export async function getCrossmintVerificationStatus (
  crossmintId: string
): Promise<CrossmintVerificationResults> {
  try {
    const x = await fetch(`https://apps.api.manifoldxyz.dev/claims/check-crossmint/${crossmintId}`, {
      method: 'GET'
    });
    if (x.status !== 200) {
      throw new Error('Non-200 response from crossmint API');
    }
    const crossmintStatusJson = await x.json();
    return crossmintStatusJson;
  } catch {
    return {
      verificationStatus: {
        seller: {
          status: CrossmintVerificationStatus.FAILED
        },
        collection: {
          status: CrossmintVerificationStatus.FAILED
        }
      }
    };
  }
}
