/**
 * Resolves ENS name and avatar key
 * caches result in session storage.
 *
 * Note: using session storage
 * since list can grow without bound.
 * Should be moved to longer term
 * storage with cache expiration logic
 * or resolved on a service.
 */
import { resolveUrl } from '@/lib/avatar';

type EnsResult = {
  resolved?: string | null;
  expiration?: Date;
  avatarSrc?: string;
};

const cacheTtl = 60 * 60 * 24 * 1000;
const cacheKeyPrefix = 'manifold/ens/';

const tryEnsFromCache = (address: string): EnsResult | null => {
  if (!address) {
    return null;
  }

  try {
    const key = address.toLowerCase();
    const itemRaw = window.sessionStorage.getItem(`${cacheKeyPrefix}${key}`);
    if (itemRaw) {
      const item = JSON.parse(itemRaw);
      if (new Date(item.expiration) > new Date()) {
        return item;
      }
    }
  } catch (error) {
    console.warn('ENS cache lookup error:', error);
  }

  return null;
};

const cacheEnsResult = (address: string, result: EnsResult): void => {
  const item = {
    ...result,
    expiration: new Date(Date.now() + cacheTtl)
  };

  window.sessionStorage.setItem(
    `${cacheKeyPrefix}${address.toLowerCase()}`,
    JSON.stringify(item)
  );
};

const resolveEns = async (address: string, includeAvatar = false): Promise<EnsResult | undefined> => {
  // Try to hit the cache first and return early

  let cached;
  if (address) {
    cached = tryEnsFromCache(address);
  }
  if (cached) {
    return {
      resolved: cached.resolved,
      avatarSrc: cached.avatarSrc
    };
  }

  // Cache miss, look up ens using provider

  let result: EnsResult;
  const provider = window.ManifoldEthereumProvider.provider();
  if (!provider) {
    console.warn('EnsResolver: no provider detected');
    return {}; // don't cache if no provider was hit
  } else {
    let ens, avatarSrc;
    try {
      if (address) {
        ens = await provider.lookupAddress(address);
      }
    } catch (e) {
      console.warn('EnsResolver: failed looking up address', e);
    }

    if (includeAvatar && ens) {
      try {
        const resolver = await provider.getResolver(ens);
        let uri = null;
        if (resolver) {
          uri = await resolver.getText('avatar');
        }

        if (uri && uri.length && address) {
          avatarSrc = await resolveUrl(uri, address, provider);
        }
      } catch (e) {
        console.warn('EnsResolver: failed looking up avatar uri', e);
      }
    }

    if (includeAvatar && !avatarSrc) {
      result = { resolved: ens };
    } else {
      result = { resolved: ens, avatarSrc };
    }
  }

  // Store the result in cache, then return it

  if (result && address) {
    cacheEnsResult(address, result);
  }

  return result;
};

const formatAddress = (address: string) : string => {
  address = address.toLowerCase();
  if (address.indexOf('0x') >= 0) {
    return `${address.slice(0, 5)}...${address.slice(-3)}`;
  } else {
    return `0x${address.slice(0, 3)}...${address.slice(-3)}`;
  }
};

// get ENS or formatted address
const resolveAddress = async (address: string) : Promise<string> => {
  const ensResult = await resolveEns(address);

  if (ensResult?.resolved) {
    return ensResult.resolved;
  } else {
    return formatAddress(address);
  }
};

const resolveEnsName = async (ens: string) : Promise<string | null> => {
  const provider = window.ManifoldEthereumProvider.provider();

  if (!provider) {
    console.warn('EnsResolver: no provider detected');
    return null; // don't cache if no provider was hit
  }

  let address = null;
  try {
    address = await provider.resolveName(ens);
  } catch (e) {
    console.warn('EnsResolver: failed looking up ENS', e);
  }

  return address;
};

export { resolveAddress, resolveEns, formatAddress, resolveEnsName };
