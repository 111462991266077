// @ts-nocheck
import { createPinia } from "pinia";
import { createApp, createVNode, render } from "vue";
import { VueQueryPlugin } from "@tanstack/vue-query";

export const renderComponentWithVNode = ({ el, component, props }) => {
  let vnode = createVNode(component, props);
  render(vnode, el);

  return () => {
    // destroy vnode
    render(null, el);
    vnode = undefined;
  };
};

export const renderComponentWithApp = ({ el, component, props }) => {
  let app = createApp(component, props);
  const pinia = createPinia();

  app.use(pinia);

  VueQueryPlugin.install(app, {
    queryClientConfig: {
      defaultOptions: {
        queries: {
          retry: false,
        },
      },
    },
  });

  app.mount(el);

  return () => {
    // destroy app/component
    app?.unmount();
    app = undefined;
  };
};
