import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';
import { JsonRpcProvider } from '@ethersproject/providers';

const erc721Abi = [
  'function ownerOf(uint256 tokenId) view returns (address)',
  'function tokenURI(uint256 _tokenId) external view returns (string)'
];

const erc1155Abi = [
  'function balanceOf(address _owner, uint256 _id) view returns (uint256)',
  'function uri(uint256 _id) view returns (string)'
];

/**
 * Resolves a url based on the varying url formats based on the ENS spec
 * - avatar spec: https://gist.github.com/Arachnid/9db60bd75277969ee1689c8742b75182
 * - modified from: https://github.com/metaphor-xyz/davatar-helpers
 */
export async function resolveUrl (uri: string, address: string, provider: JsonRpcProvider): Promise<string> {
  const match = new RegExp(/([a-z]+):\/\/(.*)/).exec(uri.toLowerCase());
  const match721 = new RegExp(/eip155:1\/erc721:(\w+)\/(\w+)/).exec(uri.toLowerCase());
  const match1155 = new RegExp(/eip155:1\/erc1155:(\w+)\/(\w+)/).exec(uri.toLowerCase());

  if (match && match.length === 3) {
    const protocol = match[1];
    const id = match[2];

    switch (protocol) {
      case 'ar': {
        const baseUrl = 'https://arweave.net';

        return fetch(`${baseUrl}/graphql`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json;charset=UTF-8'
          },
          body: JSON.stringify({
            query: `
              {
                transactions(ids: ["${id}"]) {
                  edges {
                    node {
                      id
                      owner {
                        address
                      }
                    }
                  }
                }
              }
              `
          })
        })
          .then(d => d.json())
          .then(res => res.data.transactions.edges[0].node)
          .then(tx =>
            fetch(`${baseUrl}/graphql`, {
              method: 'POST',
              headers: {
                'content-type': 'application/json;charset=UTF-8'
              },
              body: JSON.stringify({
                query: `
                {
                  transactions(owners: ["${tx.owner.address}"], tags: { name: "Origin", values: ["${tx.id}"] }, sort: HEIGHT_DESC) {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
                `
              })
            })
          )
          .then(res => res.json())
          .then(res => {
            if (res.data && res.data.transactions.edges.length > 0) {
              return `${baseUrl}/${res.data.transactions.edges[0].node.id}`;
            } else {
              return `${baseUrl}/${id}`;
            }
          });
      }
      case 'ipfs':
        return `https://gateway.ipfs.io/ipfs/${id}`;
      case 'ipns':
        return `https://gateway.ipfs.io/ipns/${id}`;
      case 'http':
      case 'https':
        return uri;
      default:
        return uri;
    }
  } else if (match721 && match721.length === 3) {
    const contractId = match721[1].toLowerCase();
    const tokenId = match721[2];
    const normalizedAddress = address && address.toLowerCase();

    if (provider) {
      const erc721Contract = new Contract(contractId, erc721Abi, provider);

      if (normalizedAddress) {
        const owner = await erc721Contract.ownerOf(tokenId);

        if (!owner || owner.toLowerCase() !== normalizedAddress) {
          throw new Error('ERC721 token not owned by address');
        }
      }

      const tokenURI = await erc721Contract.tokenURI(tokenId);
      const res = await fetch(getGatewayUrl(tokenURI, BigNumber.from(tokenId).toHexString()));
      const data = (await res.json()) as { image: string };
      return getGatewayUrl(data.image);
    }
  } else if (match1155 && match1155.length === 3) {
    const contractId = match1155[1].toLowerCase();
    const tokenId = match1155[2];

    if (provider) {
      const erc1155Contract = new Contract(contractId, erc1155Abi, provider);

      if (address) {
        const balance: BigNumber = await erc1155Contract.balanceOf(address, tokenId);
        if (balance.isZero()) {
          throw new Error('ERC1155 token not owned by address');
        }
      }

      const tokenURI = await erc1155Contract.uri(tokenId);
      const res = await fetch(getGatewayUrl(tokenURI, BigNumber.from(tokenId).toHexString()));
      const data = (await res.json()) as { image: string };
      return getGatewayUrl(data.image);
    }
  }

  return getGatewayUrl(uri);
}

export function getGatewayUrl (uri: string, tokenId?: string): string {
  const match = new RegExp(/([a-z]+)(?::\/\/|\/)(.*)/).exec(uri);

  if (!match || match.length < 3) {
    return uri;
  }

  const id = match[2];
  let url = uri;

  switch (match[1]) {
    case 'ar': {
      url = `https://arweave.net/${id}`;
      break;
    }
    case 'ipfs':
      if (id.includes('ipfs') || id.includes('ipns')) {
        url = `https://gateway.ipfs.io/${id}`;
      } else {
        url = `https://gateway.ipfs.io/ipfs/${id}`;
      }
      break;
    case 'ipns':
      if (id.includes('ipfs') || id.includes('ipns')) {
        url = `https://gateway.ipfs.io/${id}`;
      } else {
        url = `https://gateway.ipfs.io/ipns/${id}`;
      }
      break;
    case 'http':
    case 'https':
      break;
  }

  return tokenId ? url.replaceAll('{id}', tokenId) : url;
}

/**
 * @param wallet address
 * @returns 0x prefixed address first and last 3 chars, e.g. 0x123...987
 */
export function formatAddress (address: string): string {
  if (!address) {
    return address;
  }

  if (address.indexOf('0x') >= 0) {
    return `${address.slice(0, 5)}...${address.slice(-3)}`;
  } else {
    return `0x${address.slice(0, 3)}...${address.slice(-3)}`;
  }
}
