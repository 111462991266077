import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { commifiedRoundedNumberString } from '@manifoldxyz/manifold-dropsitetools-lib';

export function formatUSDCentsToDollars (amountInCents: BigNumber): string {
  const value = commifiedRoundedNumberString(formatUnits(amountInCents, 2), 2);
  const res = value.split('.');
  if (res.length === 1) {
    return value + '.00';
  } else if (res[1].length === 1) {
    return value + '0';
  } else {
    return value;
  }
}
